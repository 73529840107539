import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ConnectorId, useWalletModal } from "@pantherswap-libs/uikit";
import { injected, bsc, walletconnect } from "../../connectors";
import Web3 from "web3";
import { isProd } from "../../config";

const WalletConnect = () => {
  const { account, activate, deactivate } = useWeb3React();
  const web3 = new Web3(Web3.givenProvider);

  useEffect(() => {
    async function signIn() {
      window.ethereum.enable().then(function (accounts) {
        web3.eth.getChainId().then((chainId) => {
          if (isProd && Number(chainId) !== 56) {
            // 97 testnet
            alert(
              "You are on the wrong network. Change network to BNB Smart Chain."
            );
          } else {
            handleLogin(accounts[0]);
          }
        });
      });
    }

    signIn();
  }, []); // eslint-disable-line

  const handleLogin = (connectorId: ConnectorId) => {
    if (connectorId === "walletconnect") {
      return activate(walletconnect);
    } else if (connectorId === "bsc") {
      return activate(bsc);
    }
    return activate(injected);
  };

  const { onPresentConnectModal } = useWalletModal(
    handleLogin,
    deactivate,
    account
  );

  const { onPresentAccountModal } = useWalletModal(
    handleLogin,
    deactivate,
    account
  );

  return !account ? (
    <button
      type="button"
      className="wallet_button"
      onClick={onPresentConnectModal}
    >
      {" "}
      Connect{" "}
    </button>
  ) : (
    <button
      type="button"
      className="wallet_button"
      onClick={onPresentAccountModal}
    >
      {account}
    </button>
  );
};

export default WalletConnect;
