import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import twitter from "../../images/twitter.png";
import twitterX from "../../images/logo-x-twitter.svg";
import telegram from "../../images/telegram.png";
import social from "../../images/social.png";

const Wrapper = styled.div`
  background: var(--main-color);
  color: #fff;
  padding: 20px 0;
  width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  .social-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social-container img {
    width: 40px;
  }
  .soocial-container p {
    font-weight: 700;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footer-links img {
    width: 60px;
  }
  .footer-links a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
`;

const Footer = () => {
  return (
    <Wrapper id="footer">
      <Container fluid>
        <Row className="middeling">
          <Col md={12}>
            <div className="social-container">
              <a
                href="https://twitter.com/TheBullSoc"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterX} alt="The Bull Soc at X (Twitter)" />
              </a>
              <a
                href="https://t.me/TheBullSoc"
                target="_blank"
                rel="noreferrer"
              >
                <img src={telegram} alt="The Bull Soc at Telegram" />
              </a>
              <a
                href="https://discord.gg/VVDNN7TeE9"
                target="_blank"
                rel="noreferrer"
              >
                <img src={social} alt="The Bull Soc at Discord" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Footer;
