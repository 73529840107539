import Axios from "axios";
import Web3 from "web3";
import { getHeaders } from "../../helpers";

export default async function getTokens(collectionId, tokenIds = []) {
  const { data } = await Axios.get(
    `${process.env.REACT_APP_API_HOST}/marketplace/${collectionId}/tokens`,
    { headers: getHeaders(), params: { tokenIds: tokenIds.join(",") } }
  );

  const {
    data: { tokens = [] },
  } = data;
  return tokens.map((token) => ({
    ...token,
    price: token.price ? Web3.utils.fromWei(String(token.price)) : undefined,
  }));
}
