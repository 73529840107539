import styled from "styled-components";

export const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;

  @media (min-width: 769px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`;

export const STeamMember = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 20px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }

  p {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
  }

  .position {
    font-size: 14px;
    font-style: italic;
  }

  a {
  }
`;
