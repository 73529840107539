import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navbar";
// import MyBulls from "./components/MyBulls/MyBulls";
// import Marketplace from "./components/Marketplace/Marketplace";
// import MarketplaceListed from "./components/Marketplace/Listed";
// import BullView from "./components/Marketplace/BullView/BullView";
// import Launchpad from "./components/launchpad/Launchpad";
// import LaunchpadCollection from "./components/launchpad/Collection";

const App = () => (
  <>
    <Navbar />
    <Switch>
      {/* <Route path="/marketplace/:collectionId/:id" component={BullView} />
      <Route path="/marketplace/:collectionId" component={MarketplaceListed} />
      <Route path="/marketplace" component={Marketplace} />
      <Route path="/launchpad/:collectionId" component={LaunchpadCollection} />
      <Route path="/launchpad" component={Launchpad} />
      <Route path="/mynfts/:collectionId?" component={MyBulls} /> */}
      <Route path="/" component={Home} />
    </Switch>
  </>
);

export default App;
