import ox from "../../images/ox.png";
import goltnor from "../../images/goltnor.png";
import pat from "../../images/pat.png";
import sensei from "../../images/sensei.png";
import fx from "../../images/fx.png";

export const teamMembers = [
  {
    name: "The Ox",
    position: "Founder",
    image: ox,
    tw: "mooo",
  },
  {
    name: "Goltnor",
    position: "Marketing & Socials",
    image: goltnor,
    tw: "FrancoPetrini",
  },
  {
    name: "Pat.",
    position: "Full Stack Developer",
    image: pat,
    tw: "_cryptodev",
    gh: "nistadev",
  },
  {
    name: "Sensei",
    position: "Community Engagement",
    image: sensei,
    tw: "patron_silver",
  },
  {
    name: "Fx",
    position: "Content Creator",
    image: fx,
    tw: "Fx_BSC",
  },
];
