import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const Wrapper = styled.div`
  padding-bottom: 40px;
  height: 100vh;
  .sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
  }
  .sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sidebar a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 18px;
    margin: 8px 0;
    padding: 10px 20px;
  }
  .close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 30px 10px 0px;
    cursor: pointer;
  }
  .wallet_connect {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
`;

const Sidebar = ({ menuItems, showSidebar }) => (
  <Wrapper>
    <div className="sidebar-container">
      <div className="close">
        <IoMdClose color="#fff" size={30} onClick={showSidebar} />
      </div>
      <div className="sidebar">
        {menuItems
          .sort((a, b) => a.order - b.order)
          .map((el, i) => {
            if (el.isComponent) return el.component;
            if (el.external)
              if (el.external)
                return (
                  <a
                    key={i}
                    href={el.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {el.text}
                  </a>
                );

            return (
              <Link to={el.url} onClick={showSidebar} key={i}>
                <NavLink activeClassName="active" to={`/${el.url}`} key={i}>
                  {el.text}
                </NavLink>
              </Link>
            );
          })}
      </div>
    </div>
  </Wrapper>
);

export default Sidebar;
