import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { erc20 } from "../../../ABI/ERC20";
import { WBNB_ADDRESS } from "../../../constants";
import { useEffect, useState } from "react";

export default function useAccountBalances() {
  const [balances, setBalances] = useState({});
  const { account } = useWeb3React();

  useEffect(() => {
    async function getBalances() {
      const web3 = new Web3(Web3.givenProvider);
      const bnbBalance = await web3.eth.getBalance(account);
      const contract = new web3.eth.Contract(erc20, WBNB_ADDRESS);
      const wBnbBalance = await contract.methods.balanceOf(account).call();
      return { wbnb: wBnbBalance, bnb: bnbBalance };
    }

    const getAndSetBalances = () => {
      console.log("fetching balances...");
      getBalances().then(setBalances);
    };

    let interval;
    if (account) {
      getAndSetBalances();
      interval = setInterval(getAndSetBalances, 30000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [account]);

  return { balances };
}
