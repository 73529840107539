import { isProd } from "./site";
import { bullsAddress } from "./bulls";
import { lilBullsAddress } from "./lilbulls";
import { mpContractAddress } from "./marketplace";

const bscScanHost = `https://${!isProd ? "testnet." : ""}bscscan.com`;
const subgraphBaseHost = "https://api.thegraph.com/subgraphs/name/";
const subgraphTestnetName = "nistadev/testnet-tbs-marketplace";
const subgraphMainnetName = "nistadev/testnet-tbs-marketplace";
const subgraphHost = `${subgraphBaseHost}${
  isProd ? subgraphMainnetName : subgraphTestnetName
}`;

export {
  bscScanHost,
  subgraphHost,
  bullsAddress,
  mpContractAddress,
  lilBullsAddress,
  isProd,
};
