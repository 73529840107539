import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import useMarketplace from "./useMarketplace";

export default function useRewards() {
  const { account } = useWeb3React();
  const { getContract } = useMarketplace();
  const [rewards, setRewards] = useState(0);

  const claimRewards = async () => {
    return getContract().then(async (contract) => {
      return contract.methods.claimRewards().send({ from: account });
    });
  };

  const getRewardsBalance = () => {
    if (!account) return Promise.resolve(0);

    return getContract().then(async (contract) => {
      return contract.methods
        .getDividentBalancesForWallet()
        .call({ from: account })
        .then(Web3.utils.fromWei);
    });
  };

  useEffect(() => {
    getRewardsBalance().then((r) => setRewards(r || 0));
  }, [account]); // eslint-disable-line

  const handleClaimRewards = () => claimRewards().then(() => setRewards(0));

  return { rewards, claimRewards: handleClaimRewards };
}
