import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; // Dev ref: https://github.com/remix-run/react-router/issues/394
import { FiMenu } from "react-icons/fi";
import logo from "../../images/logo.svg";
import Sidebar from "./Sidebar";
import WalletButton from "../WalletConnect/WalletConnect";
import RewardsModal from "../RewardsModal/RewardsModal";
import AccountBalances from "./AccountBalances";
import { SLogo, SClaimRewards, Wrapper } from "./components";

const Navbar = () => {
  const { account } = useWeb3React();
  const location = useLocation();
  const [showRewards, setShowRewards] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const toggleShowRewards = () => setShowRewards((prev) => !prev);
  const showSidebar = () => setSidebar((prev) => !prev);

  const menuItems = [
    // { text: "My NFTs", url: "mynfts", order: 1 },
    // { text: "Marketplace", url: "marketplace", order: 2 },
    // { text: "Launchpad", url: "launchpad", order: 3 },
    // {
    //   text: "Merch",
    //   url: "https://www.tbsmerch.com/",
    //   external: true,
    //   order: 4,
    // },
    {
      component: <WalletButton key="wallet" />,
      isComponent: true,
      order: 8,
    },
  ];

  if (account) {
    menuItems.push(
      {
        component: <AccountBalances key="balances" />,
        isComponent: true,
        order: 6,
      },
      {
        isComponent: true,
        component: (
          <SClaimRewards onClick={toggleShowRewards} key="rewards">
            Rewards
          </SClaimRewards>
        ),
        order: 7,
      }
    );
  }

  return (
    <>
      <Wrapper>
        <Container fluid className="navbars" xs={11}>
          <Row>
            <Col xs={6} md={2}>
              <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
                <SLogo>
                  <img src={logo} alt="logo" />
                  <h1 className="logo">TBS</h1>
                </SLogo>
              </NavLink>
            </Col>
            <Col
              xs={6}
              md={10}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="menu-container">
                {menuItems
                  .sort((a, b) => a.order - b.order)
                  .map((el, i) => {
                    if (el.isComponent) return el.component;
                    if (el.external)
                      return (
                        <a
                          key={i}
                          href={el.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {el.text}
                        </a>
                      );

                    const LinkComponent = el.isHref ? HashLink : NavLink;
                    const linkProps = el.isHref
                      ? {
                          to: `/#${el.url}`,
                          className:
                            location.hash.slice(1) === el.url ? "active" : "",
                        }
                      : { activeClassName: "active", to: `/${el.url}` };

                    return (
                      <LinkComponent key={i} {...linkProps}>
                        {el.text}
                      </LinkComponent>
                    );
                  })}
              </div>
              <FiMenu
                className="hamburger"
                size="30"
                color="var(--text-color)"
                onClick={showSidebar}
              />
            </Col>
          </Row>
          {sidebar && (
            <Sidebar menuItems={menuItems} showSidebar={showSidebar} />
          )}
        </Container>
      </Wrapper>
      {showRewards && (
        <RewardsModal show={showRewards} onClose={toggleShowRewards} />
      )}
    </>
  );
};

export default Navbar;
