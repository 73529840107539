import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import gif from "../../images/gif.gif";

const Wrapper = styled.div`
  padding: 120px 100px;

  color: var(--text-color);

  .h-title {
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 35px !important;
    font-family: "Roboto", sans-serif;
  }
  .h-image img {
    border-radius: 5px;
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    padding: 120px 10px;
  }
  @media only screen and (max-width: 767px) {
    padding: 120px 50px;
  }
  @media only screen and (max-width: 520px) {
    padding: 120px 10px;
  }
  @media only screen and (max-width: 435px) {
    padding: 120px 10px;
  }
`;

const HowItWorks = () => (
  <Wrapper id="howitworks">
    <Container fluid>
      <Row className="align-items-center">
        <Col md={8}>
          <h3 className="h-title">How It works</h3>
          <p className="py-3">
            The Bull Society are a collection of 7,777 unique hand-drawn bulls
            living on the BNB Smart Chain.
            <br />
            Every Bull has its own unique DNA which means there will never be
            one identical to yours!
            <br />
            There are seven separate traits that go into each generation
            including Background Color, Base Color, Mouth, Eyes, Clothing, Hats,
            and Prop.
          </p>
          <p className="py-3">
            Your NFT is stored as an ERC721 token on the BNB Smart Chain and
            hosted on IPFS ensuring it will never leave the internet.
          </p>
        </Col>
        <Col md={4}>
          <div className="h-image">
            <img src={gif} alt="" />
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default HowItWorks;
