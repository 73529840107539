import Web3 from "web3";
import { EVENTS } from "../constants";

export const eventDataMapper = (data) => {
  const {
    address: contract,
    event,
    returnValues: { buyer, seller, askPrice, amount, claimer },
    transactionHash: txnHash,
    withBnb = true,
  } = data;

  const isCancel = event === "AskCancel";
  const isClaim = event === "RevenueClaim";

  if (isClaim) {
    return {
      claimer,
      amount: Web3.utils.fromWei(amount),
      timestamp: Date.now(),
    };
  }

  return {
    txnHash,
    event: EVENTS[event],
    currency: withBnb ? "BNB" : "WBNB",
    from: isCancel ? contract : seller,
    to: isCancel ? seller : buyer || "-",
    price: !isCancel ? Web3.utils.fromWei(askPrice) : "-",
    timestamp: Date.now(),
  };
};

export const generateRandomWord = () => {
  const r = (Math.random() + 1).toString(36).substring(2);
  const arr = r.split("");
  arr.splice(7, 1, "b");
  return arr.join("");
};

export const getHeaders = () => ({
  // "Access-Control-Allow-Origin": "*",
  // "Referrer-Policy": "no-referrer",
});
