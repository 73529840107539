import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
// import { FiltersProvider } from "./components/Marketplace/Context/Filters";
import App from "./App";
import Providers from "./Providers";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Providers>
        {/* <FiltersProvider> */}
        <App />
        {/* </FiltersProvider> */}
      </Providers>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
