import Web3 from "web3";
import useAccountBalances from "../shared/hooks/useAccountBalances";
import { SBalances, SBalance, SOtherBalance } from "./components";
import bnbLogo from "../../images/bnb.png";
import { useState } from "react";

export default function AccountBalances() {
  const [showOtherBalances, setShowOtherBalances] = useState(false);
  const { balances } = useAccountBalances();

  const toggleShowOtherBalances = () => setShowOtherBalances((prev) => !prev);

  return (
    <SBalances
      onTouchStart={toggleShowOtherBalances}
      onTouchEnd={toggleShowOtherBalances}
      onMouseEnter={toggleShowOtherBalances}
      onMouseLeave={toggleShowOtherBalances}
    >
      {balances.bnb && (
        <SBalance>
          <span>
            {Number(Web3.utils.fromWei(String(balances.bnb))).toFixed(2)} BNB
          </span>
          <span>
            <img src={bnbLogo} alt="" />{" "}
          </span>
        </SBalance>
      )}
      {showOtherBalances && balances.wbnb && (
        <SOtherBalance>
          <span>
            {Number(Web3.utils.fromWei(String(balances.wbnb))).toFixed(2)} WBNB
          </span>
        </SOtherBalance>
      )}
    </SBalances>
  );
}
