import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import bull1 from "../../images/Sq1.gif";
import bull2 from "../../images/Sq2.gif";
import bull3 from "../../images/Sq3.gif";
import bull4 from "../../images/sq4.gif";

const Wrapper = styled.div`
  width: 100vw;
  max-width: 1366px;
  margin: 0 auto;

  text-transform: uppercase;
  padding-top: 120px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  box-sizing: border-box;
  .hero-div {

  }
  .hero-div img {
    display: grid;
    column-gap: 20px;
    border-radius: 5px;
    vertical-align: middle;
    border-style: none;
    width: 100%;
  }
  .title {
    font-size: 65px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 18px;
    text-transform: none;
  }
  .hero-footer {
    background: var(--main-color);
    border-radius: 2px;
    color: #000;
    text-align: center;
    padding: 50px 30px;
    margin-top:40px;
  }
  .hero-footer p{
    font-family: 'Roboto', sans-serif;
    font-style:italic;
    font-weight:500;
    font-size:16px;
    font-weight:700;
  }
  .hero-footer h4{
    font-weight:700;
  }
  .borders {
    border: 5px solid #000;
    padding: 4px 15px;
  }
  .borders-left {
    border: 5px solid #000;
    border-right: 0;
    cursor: pointer;
    padding: 4px 15px;
  }
  .borders-right {
    border: 5px solid #000;
    border-left: 0;
    cursor: pointer;
    padding: 4px 15px;
  }
  .icon img {
    width: 100px;
  }
  .button {
    background: #000;
    border: 0;
    outline: 0;
    color: #fff;
    padding: 16px 22px;
    border-radius:5px;
    font-weight:700;
  }
  .italic {
    font-style: italic;
  }

  @media screen and (min-width: 960px) {
    .hero-text {
      padding-left: 40px;
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 10px;
    .icon img {
    width: 60px;
  }
  .hero-footer h4{
      font-size:20px;
  }
  .borders {

    padding: 4px 4px;
  }
  .borders-left {

    padding: 8px 4px;
  }
  .borders-right {

    padding: 8px 4px;
  }
  .buttons{
      display:flex;
  }
  .button{
      font-size:12px;
      padding:12px 10px;
  }
  @media only screen and (max-width: 991px) {
    padding: 120px 100px;
  }
  @media only screen and (max-width: 767px) {
    padding:120px 50px;
  }
  @media only screen and (max-width: 520px) {
    padding:120px 10px;
  }
  @media only screen and (max-width: 435px) {
    padding:120px 10px;
      .button{
          padding:4px 15px;
      }
  }
`;

const HeroSection = () => (
  <Wrapper id="adoptabull">
    <Container fluid>
      <Row style={{ gap: 15 }}>
        <Col
          md={{ order: "first", span: "3" }}
          lg={5}
          xl={3}
          sm={{ order: "last", span: "7" }}
          xs={{ order: "last", span: "12" }}
        >
          <Row>
            <Col xs={6}>
              <div className="hero-div my-2 my-2">
                <img src={bull1} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className="hero-div my-2 my-2">
                <img src={bull2} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className="hero-div my-2 my-2">
                <img src={bull3} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className="hero-div my-2 my-2">
                <img src={bull4} alt="" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={8} lg={6} xl={8} xs={{ order: "first" }} className="middeling">
          <div className="hero-text">
            <h4 className="title">
              WELCOME TO <br />
              THE BULL SOCIETY
            </h4>
            <p className="subtitle py-3">
              The Bull Society are a collection of 7,777 unique hand-drawn bulls
              living on the BNB Smart Chain. It also features a unique
              collection of 3888 Lil' Bulls.
            </p>

            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                marginTop: "20px",
                gap: "40px",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ fontSize: "20px" }}>BINANCE MARKETPLACE</h2>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                >
                  <a
                    className="link"
                    href="https://www.binance.com/es/nft/collection/the-bull-society-561134305180614657"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bulls
                  </a>
                  <a
                    className="link"
                    href="https://www.binance.com/es/nft/collection/lil-bulls-561178771146432513"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lil' Bulls
                  </a>
                </div>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ fontSize: "20px" }}>NFTKEY MARKETPLACE</h2>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                >
                  <a
                    className="link"
                    href="https://nftkey.app/collections/binancebullsoc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bulls
                  </a>
                  <a
                    className="link"
                    href="https://nftkey.app/collections/lilbulls/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lil' Bulls
                  </a>
                </div>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ fontSize: "20px" }}>PANCAKESWAP MARKETPLACE</h2>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                >
                  <a
                    className="link"
                    href="https://pancakeswap.finance/nfts/collections/0xA46A4920B40f134420b472B16b3328d74D7B6B70"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bulls
                  </a>
                  <a
                    className="link"
                    href="https://pancakeswap.finance/nfts/collections/0xA2460F62E91FE752308FF70f12F9c127F6294481"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lil' Bulls
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <h2 style={{ fontSize: "20px" }}>
              <a
                className="link"
                href="https://bscscan.com/address/0xa46a4920b40f134420b472b16b3328d74d7b6b70#code"
                target="_blank"
                rel="noopener noreferrer"
              >
                VERIFIED SMART CONTRACT ADDRESS
              </a>
            </h2>
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default HeroSection;
