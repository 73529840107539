import Web3 from "web3";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { marketplaceABI } from "../../../ABI/marketplace";
import { bullsAddress, mpContractAddress } from "../../../config";
import getTokens from "../../../api/firebase/get/tokens";

export default function useMarketplace() {
  const { collectionId = bullsAddress } = useParams();
  const { account } = useWeb3React();

  const getNFTContract = async () => {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:3000");
    return new web3.eth.Contract(marketplaceABI, mpContractAddress);
  };

  useEffect(() => {
    if (account) getWalletMarketNFTs();
  }, [account]); // eslint-disable-line

  const getWalletMarketNFTs = async () => {
    if (!collectionId || !account) return;

    return getNFTContract().then(async (contract) => {
      const { tokenIds, askInfo } = await contract.methods
        .viewAsksByCollectionAndSeller(collectionId, account, 0, 10000)
        .call();

      if (!tokenIds.length) return;

      const tokensData = await getTokens(collectionId, tokenIds);

      return tokensData.map((token) => {
        const tIndex = tokenIds.indexOf(String(token.tokenId));
        const ask = askInfo[tIndex];
        return { ...token, price: Web3.utils.fromWei(ask.price) };
      });
    });
  };

  const askOrder = (tokenId, price) => {
    return getNFTContract().then(async (contract) => {
      return contract.methods
        .createAskOrder(collectionId, `${tokenId}`, Web3.utils.toWei(price))
        .send({ from: account });
    });
  };

  const modifyOrder = (tokenId, newPrice) => {
    return getNFTContract().then(async (contract) => {
      return contract.methods
        .modifyAskOrder(collectionId, `${tokenId}`, Web3.utils.toWei(newPrice))
        .send({ from: account });
    });
  };

  const cancelOrder = (tokenId) => {
    return getNFTContract().then(async (contract) => {
      return contract.methods
        .cancelAskOrder(collectionId, tokenId)
        .send({ from: account });
    });
  };

  const buyNft = (tokenId, price) => {
    const value = Web3.utils.toWei(`${price}`);
    return getNFTContract().then(async (contract) =>
      contract.methods
        .buyTokenUsingBNB(collectionId, tokenId)
        .send({ value, from: account })
    );
  };

  return {
    askOrder,
    buyNft,
    modifyOrder,
    cancelOrder,
    getWalletMarketNFTs,
    getContract: getNFTContract,
  };
}
