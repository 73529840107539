import useRewards from "../Marketplace/hooks/useRewards";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalContainer = styled(Modal)`
  .modal-content {
    background-color: #1e2026;
    border-radius: 20px;
    position: relative;
  }
`;

const StyledTitle = styled(Modal.Title)`
  color: var(--main-color);
  font-family: "Academic-M54";
`;

const SBody = styled.span`
  color: #fff;
  font-size: 18px;
`;

const RewardsButton = styled.button`
  color: #ffffff;
  background-color: #000;
  font-weight: bold;
  border-radius: 20px;
  outline: none;
  border: 2px solid #ffbb08;
  padding: 4px 10px;
  margin-left: 10px;
`;

export default function RewardsModal({ show, onClose }) {
  const { rewards, claimRewards } = useRewards();
  return (
    <ModalContainer className="transferModal" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <StyledTitle>Pending rewards</StyledTitle>
      </Modal.Header>
      <Modal.Body>
        <SBody>{`${parseFloat(rewards).toFixed(8)} WBNB`}</SBody>
        {rewards > 0 && (
          <RewardsButton onClick={claimRewards}>Claim</RewardsButton>
        )}
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </ModalContainer>
  );
}
