import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #000;
  z-index: 15;

  .navbars {
    padding: 0 20px;
  }
  .logo {
    color: var(--text-color);
    font-size: 65px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
  }
  .menu-container a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    padding: 10px 12px;
  }
  .hamburger {
    display: none;
    cursor: pointer;
  }
  .wallet_button {
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 20px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #f3ba2f;
    border: none;
    border-radius: 8px;
    width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 769px) {
      margin-left: 12px;
    }
  }

  .wallet_button:hover {
    background-color: #c38e0d;
  }

  .menu-container {
    width: 100%;
    text-align: right;
  }

  @media only screen and (max-width: 991px) {
    .navbars {
      padding: 0 50px;
    }
    .logo {
      font-size: 50px;
    }

    .menu-container a {
      font-size: 16px;
      padding: 10px 10px;
      font-style: italic;
    }
  }
  @media only screen and (max-width: 953px) {
    .menu-container a {
      font-size: 15px;
      padding: 10px 5px;
    }
  }
  @media only screen and (max-width: 767px) {
    .navbars {
      padding: 0 30px;
    }
    .menu-container {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
`;

export const SClaimRewards = styled.button`
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  border: 2px solid #f3ba2f;
  border-radius: 8px;

  @media screen and (min-width: 769px) {
    margin-left: 12px;
    margin-bottom: 0px;
  }
`;

export const SLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: 769px) {
    img {
      width: 60px;
      height: 60px;
    }
  }
`;

export const SBalances = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  color: #f3ba2f;
  margin-bottom: 10px;
  position: relative;

  @media screen and (min-width: 769px) {
    margin-left: 12px;
    margin-bottom: 0px;
  }
`;

export const SBalance = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 0 10px;
  border-radius: 10px;
  height: 40px;

  img {
    width: 25px;
    height: 25px;
  }
`;

export const SOtherBalance = styled(SBalance)`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
`;
