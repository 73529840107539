import styled from "styled-components";

const Container = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  color: #fff;
  padding: 20px;
  margin-top: 75px;

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

export default Container;
