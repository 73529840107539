import HeroSection from "../HeroSection/HeroSection";
import HowItWorks from "../HowItWork/HowItWorks";
import RoadMap from "../RoadMap/RoadMap";
import Team from "../Team/Team";
import Footer from "../Footer/Footer";

const Home = () => (
  <>
    <HeroSection />
    <HowItWorks />
    <RoadMap />
    <Team />
    <Footer />
  </>
);

export default Home;
