import Container from "../Marketplace/Container";
import { teamMembers } from "./settings";
import { SGrid, STeamMember } from "./components";

const Team = () => (
  <Container style={{ marginBottom: 100 }} id="team">
    <h3 className="t-title">The Team</h3>
    <p className="py-4">
      The Bull Society is led by a decentralized team that has love for the BNB
      Smart Chain ecosystem. Bringing good vibes, transparency, community growth
      &amp; art through the blockchain. Our main goal is to become the number
      one Binance community giving back to all its members.
    </p>
    <SGrid className="py-4">
      {teamMembers.map((member) => (
        <STeamMember key={member.name}>
          <img src={member.image} alt="" />
          <p>
            <span className="name">{member.name}</span>
            <span className="position">{member.position}</span>
            <span style={{ display: "flex", gap: "20px" }}>
              {member.tw && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://twitter.com/${member.tw}`}
                  className="link"
                >
                  X (Twitter)
                </a>
              )}
              {member.gh && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://github.com/${member.gh}`}
                  className="link"
                >
                  GitHub
                </a>
              )}
            </span>
          </p>
        </STeamMember>
      ))}
    </SGrid>
  </Container>
);

export default Team;
